<template>
  <div class="border border-default-200 rounded-lg md:p-5 p-3 mb-2 text-sm" v-if="this.deliveryMode == 'delivery'">
    <h4 class="text-lg font-semibold text-default-800 mb-5">Morada de entrega</h4>
    <div>
      {{  currentAddress.name }} {{  currentAddress.address_number }}
      <br/>{{  currentAddress.appartment_number }}
      <br/>{{  currentAddress.zipcode }} - {{  store.city }}
      <div class="my-2" v-if="(currentAddress.obs?.length ?? 0) > 0"><b>Observações de entrega:</b> {{  currentAddress.obs }}</div>
      <div class="my-2"><b>Previsão de entrega:</b> {{  format_date(order.deliveryTimePreview, 'HH:mm') }}</div>
    </div>
  </div>
  <div class="border border-default-200 rounded-lg p-5 mb-5 text-sm" v-else>
    <h4 class="text-lg font-semibold text-default-800 mb-5">Morada para levantamento</h4>
    <div>
      <b>{{  store.name }}</b><br/>
      {{  store.address }} {{  store.address_number }}<br/>
      {{  store.zipcode }} - {{  store.city }}
      <div class="my-4"><b>Previsão de disponibilidade:</b> {{  format_date(order.deliveryTimePreview, 'HH:mm') }}</div>
    </div>
  </div>



  <div class="border border-default-200 rounded-lg p-5 mb-5 text-sm" v-if="itemsCount > 0">
    <h4 class="text-lg font-semibold text-default-800 mb-5">Valor</h4>
    <div class="mb-6">
      <div class="flex justify-between mb-3">
        <p class="text-default-500">Sub-total</p>
        <p class="text-default-700 font-medium">
          {{ toCurrency(order.subtotal, "EUR") }}
        </p>
      </div>
      <div v-if="!feePaidByStore" class="flex justify-between mb-3">
        <p class="text-default-500">Taxa de serviço</p>
        <p class="text-default-700 font-medium">
          {{ toCurrency(order.serviceFee,"EUR") }}
        </p>
      </div>
      <div class="flex justify-between mb-3" v-if="deliveryMode == 'delivery'">
        <p class="text-default-500">Taxa de entrega ({{ toDistance(currentAddress.distance, "km") }})</p>
        <p class="text-default-700 font-medium">
          {{ toCurrency(order.deliveryCost, "EUR") }}
        </p>
      </div>
      <div class="flex justify-between mb-3" v-if="order.bagCost > 0">
        <p class="text-default-500">Taxa regulatória sobre saco</p>
        <p class="text-default-700 font-medium">
          {{ toCurrency(order.bagCost, "EUR") }}
        </p>
      </div>

      <div class="border-b border-default-200 my-4"></div>
      <div class="flex justify-between mb-3">
        <p class="text-base text-default-700">Total</p>
        <p class="text-base text-default-700 font-medium">
          {{
            toCurrency(order.totalOrder - (feePaidByStore ? order.serviceFee : 0),"EUR")
          }}
        </p>
      </div>

      <div class="border-b border-default-200 my-4"></div>
      <div class="flex justify-between mb-3">
        <p class="text-middle text-default-700 m-auto mr-4">NIF</p>
        <p class="text-base text-default-700 font-medium w-full">
          <input @input="updateNIFValue" v-model="nif" type="number" max="9" class="block w-full rounded-lg py-2.5 px-4 bg-white border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"/>
        </p>
      </div>
    </div>

    <a
      href="/pay"
      class="w-full inline-flex items-center justify-center rounded-3xl border border-black bg-black px-10 py-3 text-center font-medium text-white shadow-sm transition-all duration-500 hover:bg-black-500"
    >
      Efetuar pagamento
    </a>
  </div>
</template>


<script>
import _debounce from "lodash/debounce";
import moment from 'moment'
export default {
  props: {
    store: { type: Object },
    orderValues: { type: Object },
    currentCart: { type: Object },
    currentAddress: { type: Object },
    currentnif: { type: String, default: '' },
    feePaidByStore: { type: Boolean, default: false },
  },
  data() {
    return {
      cart: {},
      order: {},
      products: [],
      itemsCount: 0,
      deliveryMode: "delivery",
      nif: '',
    };
  },
  mounted() {
    this.nif = this.currentnif;
    this.productsOnCart = [];
    for (const [key, value] of Object.entries(
      this.currentCart.products ?? {}
    )) {
      this.productsOnCart.push(value);
    }
    this.itemsCount = this.productsOnCart.length;
    this.total = this.currentCart.total ?? 0;
    this.country = this.currentCart.country;
    this.deliveryMode = this.currentCart.deliveryMode ?? "delivery";

    this.onCartUpdated({
      products: this.productsOnCart,
      itemsCount: this.itemsCount,
      deliveryMode: this.deliveryMode,
      country: this.country,
      orderValues: this.orderValues,
      nif: this.nif,
    });
    this.emitter.on("cart.updated", this.onCartUpdated);
  },
  created() {
    this.moment = moment;
  },
  
  methods: {
    onCartUpdated(data) {
      this.products = data.products;
      this.itemsCount = data.itemsCount;
      this.cart = data.currentCart;
      this.order = data.orderValues;
      this.deliveryMode = data.deliveryMode;
      this.nif = data.nif;
    },
    
    add(productId, qtd = 1) {
      if (this.deliveryMode != "delivery" && this.currentAddress.name == "")
        this.emitter.emit("address.select");
      else document.Cart.add(productId, qtd);
    },
    toggle(id) {
      document.getElementById(id).classList.toggle("hidden");
    },
    reset() {
      this.historyMode = true;
    },

    
    updateNIFValue(event) {
      this.touched = true;
      this.updateValue(event.target.value);
    },
    updateValue: _debounce(function (value) {
      this.touched = false;
      document.Cart.updateNIF(value);
    }, 600),

  toCurrency(value, currency = "EUR") {
    value = parseFloat(value, 2);
    if (typeof value !== "number") {
      return value;
    }
    var formatter = new Intl.NumberFormat("pt-PT", {
      style: "currency",
      currency: currency,
    });

    return formatter.format(value);
  },

  toDistance(value, unit = 'KM') {
    value = parseFloat(value);
    if (typeof value !== "number") {
      return value;
    }
    var formatter = new Intl.NumberFormat("pt-PT", {
    });

    return formatter.format(value.toFixed(1)) + ' ' + unit;
  },

  format_date(value, format){
        if (value) {
          return moment(String(value)).format(format)
        }
    },
  },
};
</script>
