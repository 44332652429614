<template>
  <div>
    <div class="">
      <div class="grid md:grid-cols-3 grid-cols-4 gap-4">
        <div class="md:col-span-1 col-span-4">
          <label class="block text-sm font-medium text-default-900 mb-2">Restaurante</label>
          <select v-model="currentStore" @input="updateStore($event.target.value)"
              class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50">
              <option value="*" >Todos ({{stores.length}})</option>
              <option v-for="store in stores" :key="store.id" :value="store.id" >{{ store.name }}</option>
          </select>
        </div>
        <div class="md:col-span-1 col-span-2">
          <label class="block text-sm font-medium text-default-900 mb-2">Inicio</label>
          <input
              class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
              type="date"
              v-model="startDate"
              @input="updateStartDate($event.target.value)"
            />
        </div>
        <div class="md:col-span-1 col-span-2">
          <label class="block text-sm font-medium text-default-900 mb-2">Fim</label>
            <input
              class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
              type="date"
              v-model="endDate"
              @input="updateEndDate($event.target.value)"
            />
        </div>
      </div>
    </div>



    <div class="border-b border-gray-200 dark:border-gray-700">
      <ul
        class="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400"
      >
        <li class="me-2">
          <a
            href="#"
            data-tab-target="#tabNew"
            class="inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg group gap-2"
          >
            <Icon.Play :size="15" />
            <span class="hidden md:block">Novos</span>
          </a>
        </li>
        <li class="me-2">
          <a
            href="#"
            data-tab-target="#tabDelivering"
            class="inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg group gap-2"
          >
            <Icon.Bike :size="15" />
            <span class="hidden md:block">Em entrega</span>
          </a>
        </li>
        <li class="me-2">
          <a
            href="#"
            data-tab-target="#tabCompleted"
            class="inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg group gap-2"
            aria-current="page"
          >
            <Icon.Check :size="15" />
            <span class="hidden md:block">Finalizados</span>
          </a>
        </li>
        <li class="me-2">
          <a
            href="#"
            data-tab-target="#tabCanceled"
            class="inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg group gap-2"
          >
            <Icon.Cross :size="15" />
            <span class="hidden md:block">Cancelados</span>
          </a>
        </li>
      </ul>
    </div>

    <div class="mt-4">
      <div id="tabNew" class="tab-content grid text-gray-700">
        <div class="grid grid-cols-2 gap-4">
          <h2 class="font-bold">Novos</h2>

          <div class="flex justify-end" v-if="!new_orders_loading">
            <button @click="newOrdersLoad" class="px-6 py-2 rounded-3xl bg-primary text-white capitalize transition-all hover:bg-primary">Atualizar</button>
          </div>
        </div>

        <div v-if="new_orders_loading" class="w-full flex items-center justify-center p-40">
          <div class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white" role="status">
            <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]" >Loading...</span >
          </div>
        </div>

        <div v-if="!new_orders_loading" class="grid grid-cols-1 gap-10 overflow-x-auto">

          <div v-if="new_orders.length > 0" class="grid grid-cols-1 gap-4 rounded-3xl min-w-[600px] overflow-x-auto">
            
            <div class="shadow p-4 grid grid-cols-6">
              <div>&nbsp;</div>
              <div>Code</div>
              <div>Status</div>
              <div>Criado</div>
              <div>Valor</div>
              <div>Delivery</div>
            </div>
            
            <div v-for="order in new_orders" :key="order.id" class="shadow px-4 py-2 grid grid-cols-6">
              <integrator-icon :integrator="order.integrator" class="w-8" />
              <div>{{ order.code }}</div>
              <div>{{ order.status }}</div>
              <div>{{ moment(order.created_at).format('DD/MM HH:mm') }}</div>
              <div>{{ toCurrency(order.price_with_discount ?? 0) }}</div>
              <div>{{ toCurrency(order.delivery_cost ?? 0) }}</div>
            </div>
          </div>
          <div v-else>
            Sem pedidos
          </div>
        </div>          
      </div>






      <div id="tabDelivering" class="tab-content grid text-gray-700">
        <div class="grid grid-cols-2">
          <h2 class="font-bold">Em entrega</h2>

          <div class="flex justify-end" v-if="!delivering_orders_loading">
            <button @click="deliveringOrdersLoad" class="px-6 py-2 rounded-3xl bg-primary text-white capitalize transition-all hover:bg-primary">Atualizar</button>
          </div>
        </div>
        <div v-if="delivering_orders_loading" class="w-full flex items-center justify-center p-40">
          <div class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white" role="status">
            <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]" >Loading...</span >
          </div>
        </div>

        <div v-if="!delivering_orders_loading" class="grid grid-cols-1 gap-10 overflow-x-auto">
          

          <div v-if="delivering_orders.length > 0" class="grid grid-cols-1 gap-4 rounded-3xl min-w-[600px] overflow-x-auto">
            
            <div class="shadow p-4 grid grid-cols-6">
              <div>&nbsp;</div>
              <div>Code</div>
              <div>Status</div>
              <div>Criado</div>
              <div>Valor</div>
              <div>Delivery</div>
            </div>
            
            <div v-for="order in delivering_orders" :key="order.id" class="shadow px-4 py-2 grid grid-cols-6">
              <integrator-icon :integrator="order.integrator" class="w-8" />
              <div>{{ order.code }}</div>
              <div>{{ order.status }}</div>
              <div>{{ moment(order.created_at).format('DD/MM HH:mm') }}</div>
              <div>{{ toCurrency(order.price_with_discount ?? 0) }}</div>
              <div>{{ toCurrency(order.delivery_cost ?? 0) }}</div>
            </div>
          </div>
          <div v-else>
            Sem pedidos
          </div>
        </div>          
      </div>






      <div id="tabCompleted" class="tab-content grid text-gray-700">
        <div class="grid grid-cols-2">
          <h2 class="font-bold">Finalizados</h2>

          <div class="flex justify-end" v-if="!completed_orders_loading">
            <button @click="completedOrdersLoad" class="px-6 py-2 rounded-3xl bg-primary text-white capitalize transition-all hover:bg-primary">Atualizar</button>
          </div>
        </div>
        <div v-if="completed_orders_loading" class="w-full flex items-center justify-center p-40">
          <div class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white" role="status">
            <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]" >Loading...</span >
          </div>
        </div>

        <div v-if="!completed_orders_loading" class="grid grid-cols-1 gap-10 overflow-x-auto">
          

          <div v-if="completed_orders.length > 0" class="grid grid-cols-1 gap-4 rounded-3xl min-w-[600px] overflow-x-auto">
            
            <div class="shadow p-4 grid grid-cols-6">
              <div>&nbsp;</div>
              <div>Code</div>
              <div>Status</div>
              <div>Criado</div>
              <div>Valor</div>
              <div>Delivery</div>
            </div>
            
            <div v-for="order in completed_orders" :key="order.id" class="shadow px-4 py-2 grid grid-cols-6">
              <integrator-icon :integrator="order.integrator" class="w-8" />
              <div>{{ order.code }}</div>
              <div>{{ order.status }}</div>
              <div>{{ moment(order.created_at).format('DD/MM HH:mm') }}</div>
              <div>{{ toCurrency(order.price_with_discount ?? 0) }}</div>
              <div>{{ toCurrency(order.delivery_cost ?? 0) }}</div>
            </div>
          </div>
          <div v-else>
            Sem pedidos
          </div>
        </div>          
      </div>






      <div id="tabCanceled" class="tab-content grid text-gray-700">
        <div class="grid grid-cols-2">
          <h2 class="font-bold">Cancelados</h2>

          <div class="flex justify-end" v-if="!canceled_orders_loading">
            <button @click="canceledOrdersLoad" class="px-6 py-2 rounded-3xl bg-primary text-white capitalize transition-all hover:bg-primary">Atualizar</button>
          </div>
        </div>
        <div v-if="canceled_orders_loading" class="w-full flex items-center justify-center p-40">
          <div class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white" role="status">
            <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]" >Loading...</span >
          </div>
        </div>

        <div v-if="!canceled_orders_loading" class="grid grid-cols-1 gap-10 overflow-x-auto">
          

          <div v-if="canceled_orders.length > 0" class="grid grid-cols-1 gap-4 rounded-3xl min-w-[600px] overflow-x-auto">
            
            <div class="shadow p-4 grid grid-cols-6">
              <div>&nbsp;</div>
              <div>Code</div>
              <div>Status</div>
              <div>Criado</div>
              <div>Valor</div>
              <div>Delivery</div>
            </div>
            
            <div v-for="order in canceled_orders" :key="order.id" class="shadow px-4 py-2 grid grid-cols-6">
              <integrator-icon :integrator="order.integrator" class="w-8" />
              <div>{{ order.code }}</div>
              <div>{{ order.status }}</div>
              <div>{{ moment(order.created_at).format('DD/MM HH:mm') }}</div>
              <div>{{ toCurrency(order.price_with_discount ?? 0) }}</div>
              <div>{{ toCurrency(order.delivery_cost ?? 0) }}</div>
            </div>
          </div>
          <div v-else>
            Sem pedidos
          </div>
        </div>          
      </div>




    </div>
  </div>




  

  <div
    v-if="menuPopupOpened"
    class="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none"
    id="modal-product"
  >
    <div class="absolute bg-black bg-opacity-80 inset-0 z-0"></div>
    <div
      class="w-auto p-5 relative mx-auto my-auto rounded-xl shadow-lg bg-white"
    >
      <!--content-->
      <div>
        <Icon.X
          @click="menuClosePopup"
          class="cursor-pointer absolute top-1 right-1"
        />
        <!--body-->
        <div class="text-center gap-4 p-5 flex-auto justify-center">
          <div class="mb-4" v-if="currentProductData.id == ''">
            Novo produto
          </div>
          <div class="mb-4" v-else>Editar produto</div>
          <input
            id="name"
            name="name"
            class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
            type="text"
            placeholder="Nome"
            v-model="currentProductData.name"
          />
          <div
            class="text-center flex grid-cols-2 gap-4 p-5 flex-auto justify-center"
          >
            <div>
              <image-upload
                v-if="currentProductData.id != ''"
                class="h-24"
                :name="currentProductData.id"
                :store_id="store.id"
                :maxSize="5"
                accept="png,jpg,jpeg"
                @file-uploaded="uploadImage"
              />
            </div>
            <div class="grid grid-cols-1 gap-4 w-80">
              <textarea
                id="description"
                name="description"
                rows="5"
                class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
                type="text"
                placeholder="Descrição"
                v-model="currentProductData.description"
              />
              <input
                id="price"
                name="price"
                class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
                type="text"
                placeholder="Preço"
                v-model="currentProductData.price"
              />

              <div class="mb-4">
                <label for="availible" class="">Em estoque?</label>

                <input
                  type="checkbox"
                  id="availible"
                  v-model="currentProductData.available"
                  true-value="1"
                  false-value="0"
                  class="relative w-[3.25rem] h-7 bg-default-200 focus:ring-0 checked:bg-none checked:!bg-primary border-2 border-transparent rounded-3xl cursor-pointer transition-colors ease-in-out duration-200 appearance-none focus:ring-transparent before:inline-block before:w-6 before:h-6 before:bg-white before:translate-x-0 checked:before:translate-x-full before:shadow before:rounded-3xl before:transform before:transition before:ease-in-out before:duration-200"
                />
              </div>
            </div>
          </div>
          <div class="flex justify-end">
            <button
              @click="menuProductSave"
              class="px-6 py-3 rounded-3xl bg-primary text-white capitalize transition-all hover:bg-primary"
            >
              Gravar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>



  

  <div
    v-if="menuCategoryPopupOpened"
    class="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none"
    id="modal-category"
  >
    <div class="absolute bg-black bg-opacity-80 inset-0 z-0"></div>
    <div
      class="w-auto p-5 relative mx-auto my-auto rounded-xl shadow-lg bg-white"
    >
      <!--content-->
      <div>
        <Icon.X
          @click="menuCategoryClosePopup"
          class="cursor-pointer absolute top-1 right-1"
        />
        <!--body-->
        <div class="text-center gap-4 p-5 flex-auto justify-center">
          <div class="mb-4" v-if="currentCategoryData.id == ''">
            Nova Categoria
          </div>
          <div class="mb-4" v-else>Editar categoria</div>
          <input
            id="name"
            name="name"
            class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
            type="text"
            v-model="currentCategoryData.name"
          />
          <div class="flex justify-end">
            <button
              @click="menuCategorySave"
              class="px-6 py-3 rounded-3xl bg-primary text-white capitalize transition-all hover:bg-primary"
            >
              Gravar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import * as Icon from "lucide-vue-next";
import FormData from "form-data";
import _debounce from "lodash/debounce";
import moment from 'moment';
</script>

<script>
export default {
  components: {
  },
  props: {
    stores: { type: Array },
  },
  data() {
    return {
      currentStore: "*",
      startDate: moment().add(-7, 'days').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      new_orders: [],
      new_orders_loading: false,
      
      delivering_orders: [],
      delivering_orders_loading: false,
      
      completed_orders: [],
      completed_orders_loading: false,
      
      canceled_orders: [],
      canceled_orders_loading: false,
      
      totalOrder: 0,
      progress: null,
      error: null,
      ordersLoading: false,

      menuLoading: true,
      menuItems: [],
      menuItemsDrag: false,
      menuPopupOpened: false,
      menuCategoryPopupOpened: false,
      currentProduct: {},
      currentProductData: {},
      currentCategory: {},
      currentCategoryData: {},

      imagesLoading: true,
      images: [],
      imagesNewName: "",
      imagesNewFile: null,

      
      hoursLoading: true,
      hoursItems: [],
    };
  },
  mounted() {
    const tabs = document.querySelectorAll("[data-tab-target]");
    const activeClass = "border-blue-600";

    // Select first tab by default

    tabs.forEach((tab) => {
      tab.addEventListener("click", () => {
        const targetContent = document.querySelector(tab.dataset.tabTarget);

        document.querySelectorAll(".tab-content").forEach((content) => content.classList.add("hidden"));
        targetContent.classList.remove("hidden");
        
        this.enableTab(targetContent.id);

        // Remove active class from all tabs
        document.querySelectorAll(".border-blue-600").forEach((activeTab) => activeTab.classList.remove(activeClass));

        // Add active class to clicked tab
        tab.classList.add(activeClass);

      });
    });
    
    tabs[0].click();
  },
  methods: {
    enableTab(tabName) {
      if (tabName == "tabNew") {
        this.newOrdersLoad();
      } else if (tabName == "tabDelivering") {
        this.deliveringOrdersLoad();
      } else if (tabName == "tabCompleted") {
        this.completedOrdersLoad();
      } else if (tabName == "tabCanceled") {
        this.canceledOrdersLoad();
      };
    },
    clickCurrentTab(){
      document.querySelectorAll(".border-blue-600")[0].click();
    },
    settingsTabSave(submitEvent) {
      axios
        .post("/admin/store/" + this.store.id + "/settings/update", {
          company_id: submitEvent.target.elements.company_id.value,
          name: submitEvent.target.elements.name.value,
          email: submitEvent.target.elements.email.value,
          phone: submitEvent.target.elements.phone.value,
          url: submitEvent.target.elements.url.value,
          hostnames: submitEvent.target.elements.hostnames.value,
          home_header: submitEvent.target.elements.home_header.value,          
          address: submitEvent.target.elements.address.value,
          address_number: submitEvent.target.elements.address_number.value,
          appartment_number: submitEvent.target.elements.appartment_number.value,
          zipcode: submitEvent.target.elements.zipcode.value,
          city: submitEvent.target.elements.city.value,
          state: submitEvent.target.elements.state.value,
          country: submitEvent.target.elements.country.value,
          lat: submitEvent.target.elements.lat.value,
          lng: submitEvent.target.elements.lng.value,
          service_fee: submitEvent.target.elements.service_fee.value,
          delivery_km: submitEvent.target.elements.delivery_km.value,
          delivery_base: submitEvent.target.elements.delivery_base.value,
          minutes_to_prepare: submitEvent.target.elements.minutes_to_prepare.value,
        })
        .then(() => {
          alert("updated!");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    newOrdersLoad() {
      const self = this;
      this.new_orders_loading = true;

      axios
        .post("/admin/api/orders", {'status': ['NEW', 'READY'], 'start': this.startDate, 'end': this.endDate, 'store': this.currentStore,})
        .then((response) => {
          self.new_orders = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.new_orders_loading = false;
        });
    },
    deliveringOrdersLoad() {
      const self = this;
      this.delivering_orders_loading = true;

      axios
        .post("/admin/api/orders", {'status': ['ON_DELIVERY'], 'start': this.startDate, 'end': this.endDate, 'store': this.currentStore})
        .then((response) => {
          self.delivering_orders = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.delivering_orders_loading = false;
        });
    },
    completedOrdersLoad() {
      const self = this;
      this.delivering_orders_loading = true;

      axios
        .post("/admin/api/orders", {'status': ['DELIVERED'], 'start': this.startDate, 'end': this.endDate, 'store': this.currentStore})
        .then((response) => {
          self.completed_orders = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.completed_orders_loading = false;
        });
    },
    canceledOrdersLoad() {
      const self = this;
      this.canceled_orders_loading = true;

      axios
        .post("/admin/api/orders", {'status': ['CANCELED'], 'start': this.startDate, 'end': this.endDate, 'store': this.currentStore})
        .then((response) => {
          self.canceled_orders = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.canceled_orders_loading = false;
        });
    },
    updateStore(store_id){
      this.currentStore = store_id;
      this.clickCurrentTab();
    },
    updateStartDate(start_date){
      this.startDate = start_date;
      this.clickCurrentTab();
    },
    updateEndDate(end_date){
      this.endDate = end_date;
      this.clickCurrentTab();
    },







    menuLoad() {
      const self = this;
      this.menuLoading = true;

      axios
        .get("/admin/store/" + this.store.id + "/menu")
        .then((response) => {
          self.menuItems = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.menuLoading = false;
        });
    },
    showProductDetail(product) {
      this.menuPopupOpened = true;

      this.currentProduct = product;
      this.currentProductData = Object.assign({}, product);
    },
    menuAddCategory(element) {
      this.currentCategoryData = {
        id: "",
        name: "",
      };
      this.menuCategoryPopupOpened = true;
    },
    menuAddProduct(element) {
      this.currentProductData = {
        available: 1,
        description: "",
        id: "",
        menu_category_id: element.id,
        name: "",
        price: "",
        size: null,
        uom: null,
      };
      this.menuPopupOpened = true;
    },
    menuRemoveProduct(element) {
      let self = this;
      axios
        .post("/admin/store/" + this.store.id + "/menuitem/delete", {
          id: element.id,
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          self.menuLoad();
        });
    },
    menuRemoveCategory(element) {
      let self = this;
      axios
        .post("/admin/store/" + this.store.id + "/menucategory/delete", {
          id: element.id,
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          self.menuLoad();
        });
    },
    menuProductSave() {
      this.currentProduct.name = this.currentProductData.name;
      this.currentProduct.description = this.currentProductData.description;
      this.currentProduct.price = this.currentProductData.price;
      this.currentProduct.available = this.currentProductData.available
        ? "1"
        : "0";

      axios
        .post("/admin/store/" + this.store.id + "/menuitem", {
          id: this.currentProductData.id,
          name: this.currentProductData.name,
          description: this.currentProductData.description,
          price: this.currentProductData.price,
          menu_category_id: this.currentProductData.menu_category_id,
          order: this.currentProductData.order,
          available: this.currentProductData.available,
        })
        .catch((error) => {
          console.log(error);
        });

      this.menuClosePopup();
    },
    menuProductSavePositions(menucategory_id) {
      const self = this;

      this.menuItems.forEach((category, categoryIndex) => {
        category.order = categoryIndex;
        category.menu_items.forEach((product, productIndex) => {
          product.order = productIndex;
        });
      });

      let data = [];

      if (menucategory_id == undefined) {
        this.menuItems.forEach((category, categoryIndex) => {
          data.push({
            id: category.id,
            name: category.name,
            order: category.order,
          });
        });

        axios
          .post("/admin/store/" + this.store.id + "/menupositions", {
            elements: data,
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            self.menuLoad();
          });
      } else {
        this.menuItems
          .filter((c) => c.id == menucategory_id)[0]
          .menu_items.forEach((product, productIndex) => {
            data.push({
              id: product.id,
              order: product.order,
            });
          });

        axios
          .post("/admin/store/" + this.store.id + "/menuitempositions", {
            elements: data,
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            self.menuLoad();
          });
      }
    },
    menuCategorySave() {
      this.currentCategory.name = this.currentCategoryData.name;

      axios
        .post("/admin/store/" + this.store.id + "/menucategory", {
          id: this.currentCategoryData.id,
          name: this.currentCategoryData.name,
        })
        .catch((error) => {
          console.log(error);
        });

      this.menuCategoryClosePopup();
    },
    menuCategoryClosePopup() {
      this.menuCategoryPopupOpened = false;
      this.menuLoad();
    },
    menuClosePopup() {
      this.menuPopupOpened = false;
      this.menuLoad();
    },
    menuTabSave() {
      this.menuItems.forEach((category, categoryIndex) => {
        category.order = categoryIndex;
        category.menu_items.forEach((product, productIndex) => {
          product.order = productIndex;
        });
      });
    },




    //Hours
    hoursLoad() {
      const self = this;
      this.hoursLoading = true;

      axios
        .get("/admin/store/" + this.store.id + "/hours")
        .then((response) => {
          self.hoursItems = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.hoursLoading = false;
        });
    },
    hoursAdd() {
      const self = this;
      this.hoursLoading = true;
      
      axios
        .post("/admin/store/" + this.store.id + "/hours", {
          hour_start: "11:00",
          hour_end: "23:00",
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: true,
          sunday: true,
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          self.hoursLoad();
        });
      },


      hourWeek(storeHour, week, enabled) {
      const self = this;
      this.hoursLoading = true;

      switch (week) {
        case 'monday':
          storeHour.monday = enabled;
          break;
        case 'tuesday':
          storeHour.tuesday = enabled;
          break;
        case 'wednesday':
          storeHour.wednesday = enabled;
          break;
        case 'thursday':
          storeHour.thursday = enabled;
          break;
        case 'friday':
          storeHour.friday = enabled;
          break;
        case 'saturday':
          storeHour.saturday = enabled;
          break;
        case 'sunday':
          storeHour.sunday = enabled;
          break;
      }     
      
      axios
        .post("/admin/store/" + this.store.id + "/hours", storeHour)
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          self.hoursLoad();
        });
      },
      updateStartInternalValue(hour, event) {
        this.touched = true;
        this.updateStartValue(hour, event.target.value);
      },
      updateStartValue: _debounce(function (hour, value) {
        this.touched = false;
        hour.hour_start = value;
        axios
        .post("/admin/store/" + this.store.id + "/hours", hour)
        .catch((error) => {
          console.log(error);
        })

      }, 600),
      updateEndInternalValue(hour, event) {
        this.touched = true;
        this.updateEndValue(hour, event.target.value);
      },
      updateEndValue: _debounce(function (hour, value) {
        this.touched = false;
        hour.hour_end = value;

        axios
        .post("/admin/store/" + this.store.id + "/hours", hour)
        .catch((error) => {
          console.log(error);
        })
      }, 600),
      
    hoursAdd() {
      const self = this;
      this.hoursLoading = true;
      
      axios
        .post("/admin/store/" + this.store.id + "/hours", {
          hour_start: "11:00",
          hour_end: "23:00",
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: true,
          sunday: true,
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          self.hoursLoad();
        });
      },

      removeHour(storeHour) {
        const self = this;
        
        axios
        .post("/admin/store/" + this.store.id + "/hours/delete", storeHour)
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          self.hoursLoad();
        });
      },



    //Images
    imagesLoad() {
      const self = this;
      this.imagesLoading = true;

      axios
        .get("/admin/store/" + this.store.id + "/images")
        .then((response) => {
          self.images = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          self.imagesLoading = false;
        });
    },

    imagesRemove(image) {
      const self = this;
      this.imagesLoading = true;

      axios
        .post("/admin/store/" + this.store.id + "/image/delete", {
          id: image.id,
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          self.imagesLoad();
        });
    },

    imagesAddImageChanged(event) {
        this.imagesNewFile = event.target.files[0];
    },

    imagesAddImage() {
      let self = this;
      self.imagesLoading = true;

      let data = new FormData();
      data.append("name", this.imagesNewName);
      data.append("image", this.imagesNewFile);

      this.imagesNewName = '';
      this.$refs.imagesNewFile.value = '';

      axios
        .post("/admin/images/upload/" + this.store.id, data, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
          },
        })
        .catch((error) => {
          console.log(error);
        })
        .finally((error) => {
          self.imagesLoad();
        });
    },

    toCurrency(value) {
      value = parseFloat(value, 2);
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("pt-PT", {
        style: "currency",
        currency: "EUR",
      });

      return formatter.format(value);
    },
  },
};
</script>
