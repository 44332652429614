<template>
  <div>
    <div class="border-b border-gray-200 dark:border-gray-700">
      <ul
        class="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400"
      >
        <li class="me-2">
          <a
            href="#"
            data-tab-target="#tabConfig"
            class="inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg group gap-2"
          >
            <Icon.Settings :size="15" />
            <span class="hidden md:block">Configurações</span>
          </a>
        </li>
        <li class="me-2">
          <a
            href="#"
            data-tab-target="#tabUsers"
            class="inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg group gap-2"
          >
            <Icon.User :size="15" />
            <span class="hidden md:block">Utilizadores</span>
          </a>
        </li>
      </ul>
    </div>

    <div class="mt-4">
      <div id="tabConfig" class="tab-content grid gap-5 text-gray-700 min-h-60">
        <h2 class="font-bold my-4">Configurações</h2>

        <form
          @submit.prevent="settingsTabSave"
          @keydown.enter="$event.preventDefault()"
        >
          <div class="grid xl:grid-cols-3 xs:grid-cols-1 gap-x-10 gap-y-5">

            <div>
              <label
                class="block text-sm font-medium text-default-900 mb-2"
                for="name"
                >Nome</label
              >
              <input
                id="name"
                name="name"
                class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
                type="text"
                :value="company.name"
              />
            </div>

            <div>
              <label
                class="block text-sm font-medium text-default-900 mb-2"
                for="email"
                >E-mail</label
              >
              <input
                id="email"
                name="email"
                class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
                type="text"
                :value="company.email"
              />
            </div>

            <div>
              <label
                class="block text-sm font-medium text-default-900 mb-2"
                for="mobile"
                >Telemóvel</label
              >
              <input
                id="mobile"
                name="mobile"
                class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
                type="text"
                :value="company.mobile"
              />
            </div>

            <div>
              <label
                class="block text-sm font-medium text-default-900 mb-2"
                for="phone"
                >Fixo</label
              >
              <input
                id="phone"
                name="phone"
                class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
                type="text"
                :value="company.phone"
              />
            </div>
          </div>

          <div class="flex justify-end">
            <button
              type="submit"
              class="px-6 py-3 rounded-3xl bg-primary text-white capitalize transition-all hover:bg-primary"
            >
              Gravar
            </button>
          </div>
        </form>
      </div>

      <div id="tabUsers" class="hidden tab-content text-gray-700 min-h-60">
        <h2 class="font-bold my-4">Utilizadores</h2>
        <div
          v-if="usersLoading"
          class="w-full flex items-center justify-center p-40"
        >
          <div
            class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
            role="status"
          >
            <span
              class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
              >Loading...</span
            >
          </div>
        </div>

        <div v-if="!usersLoading" class="grid grid-cols-1 gap-10">

          <div
            class="max-w-screen-lg rounded-md border border-gray-100 text-gray-600 shadow-md grid grid-cols-1 gap-2"
            v-for="user in users"
            :key="user.id"
          >
            <div class="grid grid-cols-5 gap-4">
              <Icon.Trash class="m-auto cursor-pointer" @click="removeUser(user)"/>
              <label class="block text-sm font-medium text-default-900 mb-2" 
                        @click="showUserDetail(user)">{{ user.name }}</label>
            </div>
          </div>


          <Icon.Plus @click="userAdd()" />
        </div>
      </div>
    </div>
  </div>




  

  <div
    v-if="userPopupOpened"
    class="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none"
    id="modal-user"
  >
    <div class="absolute bg-black bg-opacity-80 inset-0 z-0"></div>
    <div
      class="w-auto p-5 relative mx-auto my-auto rounded-xl shadow-lg bg-white"
    >
      <!--content-->
      <div>
        <Icon.X
          @click="userClosePopup"
          class="cursor-pointer absolute top-1 right-1"
        />
        <!--body-->
        <div class="text-center grid grid-cols-1 gap-4 p-5 flex-auto justify-center">
          <div class="mb-4" v-if="currentUserData.id == ''">
            Novo utilizador
          </div>
          <div class="mb-4" v-else>Editar utilizador</div>
          <input
            id="name"
            name="name"
            class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
            type="text"
            placeholder="Nome"
            v-model="currentUserData.name"
          />
          
          <input
            id="lastname"
            name="lastname"
            class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
            type="text"
            placeholder="Apelido"
            v-model="currentUserData.lastname"
          />
          
          <input
            id="email"
            name="email"
            class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
            type="email"
            placeholder="Email"
            v-model="currentUserData.email"
          />
          
          <input
            id="stores"
            name="stores"
            class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
            type="text"
            placeholder="Lojas"
            v-model="currentUserData.stores"
          />
          
          <input
            id="mobile"
            name="mobile"
            class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
            type="phone"
            placeholder="Telemóvel"
            v-model="currentUserData.mobile"
          />
          
          <input
            id="birth_date"
            name="birth_date"
            class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
            type="date"
            placeholder="Data de nascimento"
            v-model="currentUserData.birth_date"
          />
          
          <input
            id="password"
            name="password"
            class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
            type="text"
            placeholder="Senha - preencha se quiser alterar"
            v-model="currentUserData.password"
          />

          <div class="flex justify-end">
            <button
              @click="userSave"
              class="px-6 py-3 rounded-3xl bg-primary text-white capitalize transition-all hover:bg-primary"
            >
              Gravar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>



  

  <div
    v-if="menuCategoryPopupOpened"
    class="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none"
    id="modal-category"
  >
    <div class="absolute bg-black bg-opacity-80 inset-0 z-0"></div>
    <div
      class="w-auto p-5 relative mx-auto my-auto rounded-xl shadow-lg bg-white"
    >
      <!--content-->
      <div>
        <Icon.X
          @click="menuCategoryClosePopup"
          class="cursor-pointer absolute top-1 right-1"
        />
        <!--body-->
        <div class="text-center gap-4 p-5 flex-auto justify-center">
          <div class="mb-4" v-if="currentCategoryData.id == ''">
            Nova Categoria
          </div>
          <div class="mb-4" v-else>Editar categoria</div>
          <input
            id="name"
            name="name"
            class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
            type="text"
            v-model="currentCategoryData.name"
          />
          <div class="flex justify-end">
            <button
              @click="menuCategorySave"
              class="px-6 py-3 rounded-3xl bg-primary text-white capitalize transition-all hover:bg-primary"
            >
              Gravar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import * as Icon from "lucide-vue-next";
import FormData from "form-data";
import draggable from "vuedraggable";
import _debounce from "lodash/debounce";
</script>

<script>
export default {
  components: {
    draggable,
  },
  props: {
    company: { type: Object },
  },
  data() {
    return {
      visibility: false,
      userPopupOpened: false,
      menuCategoryPopupOpened: false,

      usersLoading: true,
      users: [],

      currentUser: {},
      currentUserData: {},
    };
  },
  mounted() {
    const tabs = document.querySelectorAll("[data-tab-target]");
    const activeClass = "border-blue-600";

    // Select first tab by default
    tabs[0].classList.add(activeClass);
    document.querySelector("#tabConfig").classList.remove("hidden");

    tabs.forEach((tab) => {
      tab.addEventListener("click", () => {
        const targetContent = document.querySelector(tab.dataset.tabTarget);

        document
          .querySelectorAll(".tab-content")
          .forEach((content) => content.classList.add("hidden"));
        targetContent.classList.remove("hidden");

        // Remove active class from all tabs
        document
          .querySelectorAll(".border-blue-600")
          .forEach((activeTab) => activeTab.classList.remove(activeClass));

        // Add active class to clicked tab
        tab.classList.add(activeClass);

        this.enableTab(targetContent.id);
      });
    });
  },
  created() {
  },
  methods: {
    onCartUpdated(data) {
      this.visibility = data.itemsCount > 0;
      this.totalOrder = data.total;
    },
    enableTab(tabName) {
      if (tabName == "tabUsers") {
        this.usersLoad();
      } else if (tabName == "tabHours") {
        this.hoursLoad();
      } else if (tabName == "tabImages") {
        this.imagesLoad();
      }
    },
    settingsTabSave(submitEvent) {
      axios
        .post("/admin/company/" + this.company.id + "/update", {
          name: submitEvent.target.elements.name.value,
          email: submitEvent.target.elements.email.value,
          mobile: submitEvent.target.elements.mobile.value,
          phone: submitEvent.target.elements.phone.value,
        })
        .then(() => {
          alert("updated!");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    usersLoad() {
      const self = this;
        this.usersLoading = true;

        axios
        .get("/admin/company/" + this.company.id + "/users/list")
        .then((response) => {
          self.users = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.usersLoading = false;
        });
    },
    userAdd(){
      this.currentUserData = {
        id: "",
        company_id: this.company_id,
        enabled: '1',
        name: "",
        lastname: "",
        email: "",
        stores: [],
        phone: "",
        mobile: "",
        birth_date: "",
        password: "",
      };
      this.userPopupOpened = true;
    },
    userSave() {      
      this.currentUser.company_id = this.company.id;
      this.currentUser.name = this.currentUserData.name;
      this.currentUser.lastname = this.currentUserData.lastname;
      this.currentUser.email = this.currentUserData.email;
      this.currentUser.mobile = this.currentUserData.mobile;
      this.currentUser.phone = this.currentUserData.phone;
      this.currentUser.birth_date = this.currentUserData.birth_date;
      this.currentUser.password = this.currentUserData.password;
      this.currentUser.enable = this.currentUserData.enable ? "1" : "0";

      axios
        .post("/admin/company/" + this.company.id + "/users/save", this.currentUser)
        .catch((error) => {
          console.log(error);
        });

      this.userClosePopup();
    },
    removeUser(user){
      axios
        .delete("/admin/company/" + this.company.id + "/user/" + user.id)
        .catch((error) => {
          console.log(error);
        });

      this.userClosePopup();
    },
    userClosePopup() {
      this.userPopupOpened = false;
      this.usersLoad();
    },
    showUserDetail(user) {
      this.userPopupOpened = true;

      this.currentUser = user;
      this.currentUserData = Object.assign({}, user);
    },



    showProductDetail(product) {
      this.userPopupOpened = true;

      this.currentProduct = product;
      this.currentProductData = Object.assign({}, product);
    },
    menuAddCategory(element) {
      this.currentCategoryData = {
        id: "",
        name: "",
      };
      this.menuCategoryPopupOpened = true;
    },
    menuAddProduct(element) {
      this.currentProductData = {
        available: 1,
        description: "",
        id: "",
        menu_category_id: element.id,
        name: "",
        price: "",
        size: null,
        uom: null,
      };
      this.userPopupOpened = true;
    },
    menuRemoveProduct(element) {
      let self = this;
      axios
        .post("/admin/store/" + this.store.id + "/menuitem/delete", {
          id: element.id,
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          self.menuLoad();
        });
    },
    menuRemoveCategory(element) {
      let self = this;
      axios
        .post("/admin/store/" + this.store.id + "/menucategory/delete", {
          id: element.id,
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          self.menuLoad();
        });
    },
    menuTabSave() {
      this.menuItems.forEach((category, categoryIndex) => {
        category.order = categoryIndex;
        category.menu_items.forEach((product, productIndex) => {
          product.order = productIndex;
        });
      });
    },




    //Hours
    hoursLoad() {
      const self = this;
      this.hoursLoading = true;

      axios
        .get("/admin/store/" + this.store.id + "/hours")
        .then((response) => {
          self.hoursItems = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.hoursLoading = false;
        });
    },
    hoursAdd() {
      const self = this;
      this.hoursLoading = true;
      
      axios
        .post("/admin/store/" + this.store.id + "/hours", {
          hour_start: "11:00",
          hour_end: "23:00",
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: true,
          sunday: true,
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          self.hoursLoad();
        });
      },


      hourWeek(storeHour, week, enabled) {
      const self = this;
      this.hoursLoading = true;

      switch (week) {
        case 'monday':
          storeHour.monday = enabled;
          break;
        case 'tuesday':
          storeHour.tuesday = enabled;
          break;
        case 'wednesday':
          storeHour.wednesday = enabled;
          break;
        case 'thursday':
          storeHour.thursday = enabled;
          break;
        case 'friday':
          storeHour.friday = enabled;
          break;
        case 'saturday':
          storeHour.saturday = enabled;
          break;
        case 'sunday':
          storeHour.sunday = enabled;
          break;
      }     
      
      axios
        .post("/admin/store/" + this.store.id + "/hours", storeHour)
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          self.hoursLoad();
        });
      },
      updateStartInternalValue(hour, event) {
        this.touched = true;
        this.updateStartValue(hour, event.target.value);
      },
      updateStartValue: _debounce(function (hour, value) {
        this.touched = false;
        hour.hour_start = value;
        axios
        .post("/admin/store/" + this.store.id + "/hours", hour)
        .catch((error) => {
          console.log(error);
        })

      }, 600),
      updateEndInternalValue(hour, event) {
        this.touched = true;
        this.updateEndValue(hour, event.target.value);
      },
      updateEndValue: _debounce(function (hour, value) {
        this.touched = false;
        hour.hour_end = value;

        axios
        .post("/admin/store/" + this.store.id + "/hours", hour)
        .catch((error) => {
          console.log(error);
        })
      }, 600),
      
    hoursAdd() {
      const self = this;
      this.hoursLoading = true;
      
      axios
        .post("/admin/store/" + this.store.id + "/hours", {
          hour_start: "11:00",
          hour_end: "23:00",
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: true,
          sunday: true,
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          self.hoursLoad();
        });
      },

      removeHour(storeHour) {
        const self = this;
        
        axios
        .post("/admin/store/" + this.store.id + "/hours/delete", storeHour)
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          self.hoursLoad();
        });
      },



    //Images
    imagesLoad() {
      const self = this;
      this.imagesLoading = true;

      axios
        .get("/admin/store/" + this.store.id + "/images")
        .then((response) => {
          self.images = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          self.imagesLoading = false;
        });
    },

    imagesRemove(image) {
      const self = this;
      this.imagesLoading = true;

      axios
        .post("/admin/store/" + this.store.id + "/image/delete", {
          id: image.id,
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          self.imagesLoad();
        });
    },

    imagesAddImageChanged(event) {
        this.imagesNewFile = event.target.files[0];
    },

    imagesAddImage() {
      let self = this;
      self.imagesLoading = true;

      let data = new FormData();
      data.append("name", this.imagesNewName);
      data.append("image", this.imagesNewFile);

      this.imagesNewName = '';
      this.$refs.imagesNewFile.value = '';

      axios
        .post("/admin/images/upload/" + this.store.id, data, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
          },
        })
        .catch((error) => {
          console.log(error);
        })
        .finally((error) => {
          self.imagesLoad();
        });
    },

    toCurrency(value) {
      value = parseFloat(value, 2);
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("pt-PT", {
        style: "currency",
        currency: "EUR",
      });

      return formatter.format(value);
    },
  },
};
</script>
