<template>
  <div class="grid gap-4 md:grid-cols-2 grid-cols-1 sm:px-6 lg:px-8 space-y-6">
    <div class="p-4 sm:p-8 bg-white shadow sm:rounded-lg">
      <div class="max-w-xl">
        <header>
          <h2 class="text-lg font-medium text-gray-900">
            Dados pessoais
          </h2>
        
          <p class="mt-1 text-sm text-gray-600">
            Atualize as informações de perfil e endereço de e-mail da sua conta.
          </p>      
        </header>
        
        <div class="grid md:grid-cols-2 grid-cols-2 gap-4 mt-4">
          <div class="md:col-span-1 col-span-2">
            <label class="block text-sm font-medium text-default-900 mb-2">Nome</label>
              <input
                class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
                type="text" v-model="name"
              />
          </div>
          
          <div class="md:col-span-1 col-span-2">
            <label class="block text-sm font-medium text-default-900 mb-2">Apelido</label>
              <input
                class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
                type="text" v-model="lastname"
              />
          </div>
          
          <div class="md:col-span-1 col-span-2">
            <label class="block text-sm font-medium text-default-900 mb-2">Email</label>
              <input
                class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
                type="email" v-model="email"
              />
          </div>
          
          <div class="md:col-span-1 col-span-2">
            <label class="block text-sm font-medium text-default-900 mb-2">Telemóvel</label>
              <vue-tel-input v-model="phone" @validate="phone_updated" />
          </div>

          <div class="gap-4 col-span-2 justify-center h-16 relative">
              <div class="text-red-500" v-if="profileError != ''">{{ profileError }}</div>
              <button @click="saveProfileData" class="absolute right-0 items-center px-6 py-3 rounded-3xl text-base bg-primary text-white capitalize transition-all hover:bg-primary">Gravar</button>
          </div>
        </div>
      </div>
    </div>

    <div class="p-4 sm:p-8 bg-white shadow sm:rounded-lg">
      <div class="max-w-xl">
        <header>
          <h2 class="text-lg font-medium text-gray-900">
            Atualizar palavra-passe
          </h2>
        
          <p class="mt-1 text-sm text-gray-600">
            Certifique-se de que sua conta esteja usando uma senha longa e aleatória para permanecer segura.
          </p>      
        </header>
        
        <div class="grid grid-cols-1 gap-4 mt-4">
          <div class="">
            <label class="block text-sm font-medium text-default-900 mb-2">Palavra-passe atual</label>
              <input
                class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
                type="password" v-model="current_password" name="_current_passwordw" autocomplete="off"
              />
          </div>
          
          <div class="">
            <label class="block text-sm font-medium text-default-900 mb-2">Palavra-passe nova</label>
              <input
                class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
                type="password" v-model="password" name="_passwordw" autocomplete="off"
              />
          </div>
          
          <div class="">
            <label class="block text-sm font-medium text-default-900 mb-2">Confirme a nova palavra-passe</label>
              <input
                class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
                type="password" v-model="password_confirmation" name="_password_confirmationw" autocomplete="off"
              />
          </div>

          <div class="gap-4 justify-center h-16 relative">
              <div class="text-red-500" v-if="passwordError != ''">{{ passwordError }}</div>
              <button @click="saveNewPasswordData" class="absolute right-0 items-center px-6 py-3 rounded-3xl text-base bg-primary text-white capitalize transition-all hover:bg-primary">Gravar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.vue-tel-input {
    border-radius: 0.5rem !important;
    border-color: hsl(214.29999999999995 31.8% 91.4% / 1) !important;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    padding-left: 0.1rem;
    padding-right: 0.1rem;
}
</style>

<script setup>
import * as Icon from "lucide-vue-next";
import moment from 'moment'
</script>

<script>
export default {
  props: {
    user: { type: Object },
  },
  data() {
    return {
      name: "",
      lastname: "",
      email: "",
      phone: "",
      phone_valid: false,
      current_password: "",
      password: "",
      password_confirmation: "",

      profileError: '',
      passwordError: '',
    };
  },
  mounted() {
    this.name = this.user.name;
    this.lastname = this.user.lastname;
    this.email = this.user.email;
    this.phone = this.user.phone;
  },
  created() {
  },
  methods: {

    phone_updated(phoneObject){
      this.phone_valid = phoneObject.valid;
    },

    saveProfileData(){
      this.profileError = '';

      if(this.name.length < 3) this.profileError = 'Preencha corretamente seu nome';
      else if(this.lastname.length < 3) this.profileError = 'Preencha corretamente seu apelido';
      else if(this.lastname.length < 3) this.profileError = 'Preencha corretamente seu apelido';
      else if(!this.phone_valid) this.profileError = 'Preencha corretamente seu telemóvel';
      else if(!this.validateEmail(this.email)) this.profileError = 'Preencha corretamente seu email';
      
      if(this.profileError != '') return;

      axios
        .post("/minha-conta", {
          name: this.name,
          lastname: this.lastname,
          email: this.email,
          phone: this.phone,
        })
        .then(() => {
          this.profileError = 'Atualização realizada com sucesso';
        })
        .catch((error) => {
          this.profileError = error.response.data.error;
          console.log(error);
        });
    },
    saveNewPasswordData(){
      this.passwordError = '';

      if(this.current_password.length < 3) this.passwordError = 'Preencha corretamente sua palavra-passe';
      else if(this.password.length < 3) this.passwordError = 'Preencha corretamente a nova palavra-passe';
      else if(this.password_confirmation.length < 3) this.passwordError = 'As palavras-passem não são iguais';
      else if(this.password != this.password_confirmation) this.passwordError = 'As palavras-passem não são iguais';
      
      if(this.passwordError != '') return;

      axios
        .post("/minha-conta/password", {
          current_password: this.current_password,
          password: this.password,
          password_confirmation: this.password_confirmation,
        })
        .then(() => {
          this.passwordError = 'Atualização realizada com sucesso';
        })
        .catch((error) => {
          this.passwordError = error.response.data.error;
          console.log(error);
        });
    },
    validateEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
  },
};
</script>
