 <template>
    <div class="flex items-center justify-between w-full mb-6">
      <h1 class="text-xl font-bold">
          Integrações
      </h1>
    </div>
    <div class="grid grid-col-1 gap-4">

      <Loading :enabled="loading" />


      <div v-if="!loading" class="flex-row gap-3 grow min-h-full p-2" style="min-height: calc(100vh - 170px);">
        <p class="">Para realizar a integração com a UberEats, basta inserir o ID da loja na UberEats no campo abaixo e carregar no botão "Realizar integração"</p>
        
        

        <div class="grid grid-cols-2 gap-4 my-6">
          <input type="text" placeholder="Id Loja UberEats" class="block w-full bg-transparent rounded-3xl border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
          v-model="deliveryUberEatsStoreId" />

            <button
              @click="startUberEatsIntegration"
              class="px-6 py-3 rounded-3xl bg-black text-white capitalize transition-all hover:bg-black-500"
            >
              Realizar Integração
            </button>
        </div>

        <div v-if="deliveryUberEatsStoreError != ''" class="text-red-600">
          {{ deliveryUberEatsStoreError }}
        </div>
      </div>

  </div>

</template>

<script setup>
import * as Icon from "lucide-vue-next";
import _debounce from "lodash/debounce";
import Loading from "../Loading.vue";
</script>

<script>
export default {
  components: {
  },
  props: {
    store: { type: Object },
    ubereats_client_id: { type: String, default: '' },
  },
  data() {
    return {
      loading: false,
      deliveryUberEatsStoreId: this.store.config.find((cfg) => cfg.name == 'ubereats_store_id_temp')?.content ?? '',
      deliveryUberEatsStoreError: '',
    }  
  },
  mounted() {
  },
  methods: {
    loadData() {
    },
    startUberEatsIntegration (){
      this.deliveryUberEatsStoreError = '';

      if(this.deliveryUberEatsStoreId == null || this.deliveryUberEatsStoreId == '' || this.deliveryUberEatsStoreId.length < 30 || this.deliveryUberEatsStoreId.length > 50){
        this.deliveryUberEatsStoreId = "";
        this.deliveryUberEatsStoreError = "Insira corretamente o ID da sua loja na UberEats";
        return;
      }

      console.log('https://login.uber.com/oauth/v2/authorize?client_id='+this.ubereats_client_id+'&response_type=code&redirect_uri=https://app.eatz.pt/uber/integration/callback%3Fstore%3D' + this.store.id + '%26uber_store%3D' + this.deliveryUberEatsStoreId);
      window.open('https://login.uber.com/oauth/v2/authorize?client_id='+this.ubereats_client_id+'&response_type=code&redirect_uri=https://app.eatz.pt/uber/integration/callback%3Fstore%3D' + this.store.id + '%26uber_store%3D' + this.deliveryUberEatsStoreId)
    },
  },
};
</script>
