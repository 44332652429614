 <template>
    <div class="flex items-center justify-between w-full mb-6">
      <h1 class="text-xl font-bold">
          Definições - Variáveis
      </h1>
        <button @click="create" v-if="!loading" class="p-3 rounded-full bg-black text-white capitalize transition-all hover:bg-slate-600">
          <Icon.Plus :size="15" />
        </button>
    </div>
    <div class="grid grid-col-1 gap-4">

      <Loading :enabled="loading" />


      <div v-if="!loading" class="flex-row gap-3 grow min-h-full p-2" style="min-height: calc(100vh - 170px);">


        <div class="flex flex-wrap -mx-3">
          <div class="flex-none w-full px-4">
            <div class="flex gap-2 font-bold">
              <div class="flex-1">Nome</div>
              <div class="flex-1">Valor</div>
              <div class="flex-none w-14">&nbsp;</div>
            </div>
            <div class="flex gap-2 mb-2 cursos-pointer w-full" v-for="config in configs" :key="config.id">
              <div class="cursor-pointer flex-1 line-clamp-1" @click="edit(config)">{{ config.name }}</div>
              <div class="cursor-pointer flex-1 line-clamp-1" @click="edit(config)">{{ config.content }}</div>
              <div class="cursor-pointer flex-none w-14"><Icon.Trash :size="15" @click="remove(config)"/></div>
            </div>
          </div>
        </div>
      </div>

  </div>
  

  <div
    v-if="newConfigPopup"
    class=" h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none"
    id="modal-product-addon"
  >

    <div class="absolute bg-black bg-opacity-80 inset-0 z-0"></div>
    <div
      class="w-2/4 relative mx-auto my-auto rounded-xl shadow-lg bg-white overflow-y-auto"
    >
      <!--content-->
      <div>
        <Icon.X
          @click="reset"
          class="cursor-pointer absolute top-1 right-1 z-40"
        />
        <!--body-->
        <div class="text-center grid grid-cols-1 gap-4 justify-center p-4">
          <h1 v-if="currentConfig.id">Atualizar configuração</h1>
          <h1 v-else>Nova configuração</h1>

          <input v-model="currentConfig.name" type="text" placeholder="Nome" class="block w-full rounded-lg py-2.5 px-4 bg-white border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"/>
          
          <input v-model="currentConfig.content" type="text" placeholder="Valor" class="block w-full rounded-lg py-2.5 px-4 bg-white border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"/>
        
          <button @click="add" class="px-6 py-3 rounded-3xl bg-black text-white capitalize transition-all hover:bg-slate-600">
            Gravar
          </button>
        </div>

      </div>
    </div>
  </div>

</template>

<script setup>
import * as Icon from "lucide-vue-next";
import _debounce from "lodash/debounce";
import Loading from "../Loading.vue";
</script>

<script>
export default {
  components: {
  },
  props: {
    store_id: { type: String },
  },
  expose: ['load'],
  data() {
    return {
      loading : false,
      newConfigPopup : false,
      currentConfig : {},
      configs: [],
    };
  },
  mounted() {
    this.reset();
  },
  methods: {
    create(){
      this.newConfigPopup = true;
      this.currentConfig = {
        name : '',
        content : '',
      };
    },
    edit(config){
      this.newConfigPopup = true;
      this.currentConfig = {
        id : config.id,
        name : config.name,
        content : config.content,
      };
    },
    remove(config){
      axios
          .delete("/admin/store/" + this.store_id + "/config/" + config.id)
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.reset();
          });
    },
    reset(){
      this.loading = false;
      this.newConfigPopup = false;
      this.currentConfig = {
        name : '',
        content : '',
      };
      this.load();
    },
    add(){
      const self = this;
      this.loading = true;

      if(this.currentConfig.id){
        axios
          .put("/admin/store/" + this.store_id + "/config/" + this.currentConfig.id, this.currentConfig)
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            self.reset();
          });
      }else{
        axios
          .post("/admin/store/" + this.store_id + "/config", this.currentConfig)
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            self.reset();
          });
        }
    },
    load(){
      console.log('store', this.store_id);
      const self = this;
      this.loading = true;

      axios
      .get("/admin/store/" + this.store_id + "/config")
      .then((response) => {
        self.configs = response.data;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        self.loading = false;
      });
    },
  },
};

</script>
