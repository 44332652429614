<template>    
    <div class="grid md:grid-cols-2 grid-cols-1 gap-4 mb-4">

    <div class="gap-4">
      <div class="w-full px-8 py-4" v-if="orderValues.type == 'delivery' && orderValues.status != 'canceled' && orderValues.status != 'ready'">
        <div class="relative flex items-center justify-between w-full mx-0">
          <div class="absolute left-0 top-2/4 h-0.5 w-[98%] -translate-y-2/4 bg-gray-300"></div>
          <div class="absolute left-0 top-2/4 h-0.5 w-[98%] -translate-y-2/4 bg-gray-900 transition-all duration-500">
          </div>
          <div class="relative z-10 grid w-10 h-10 font-bold text-white transition-all duration-300 bg-gray-900 rounded-full place-items-center">1</div>

          <div class="relative z-10 grid w-10 h-10 font-bold text-white transition-all duration-300 bg-gray-900 rounded-full place-items-center"
          v-if="orderValues.status == 'ON_DELIVERY' || orderValues.status == 'DELIVERED'">2</div>
          <div class="relative z-10 grid w-10 h-10 font-bold text-gray-900 transition-all duration-300 bg-gray-300 rounded-full place-items-center"
          v-else>2</div>

          <div class="relative z-10 grid w-10 h-10 font-bold text-white transition-all duration-300 bg-gray-900 rounded-full place-items-center"
          v-if="orderValues.status == 'DELIVERED'">3</div>
          <div class="relative z-10 grid w-10 h-10 font-bold text-gray-900 transition-all duration-300 bg-gray-300 rounded-full place-items-center"
          v-else>3</div>
        </div>
      </div>

      <div v-if="orderValues.type == 'delivery'">
        <div class="">
          <div class="text-lg font-bold">
            Pedido #{{orderValues.code}}
          </div>
          <div class="text-lg font-bold" v-if="orderValues.status == 'NEW' || orderValues.status == 'READY'">
            Estamos a preparar seu pedido...
          </div>
          <div class="text-lg font-bold" v-if="orderValues.status == 'ON_DELIVERY'">
            A caminho da sua localização...
          </div>
          <div class="text-lg font-bold" v-if="orderValues.status == 'CANCELED'">
            Seu pedido está cancelado
          </div>
        </div>
        <div v-if="orderValues.status != 'CANCELED'">
          <div v-if="orderValues.status != 'DELIVERED'">
            Chegada prevista: {{ format_date(orderValues.estimated_delivery_at, 'HH:mm') }}
          </div>
          <div v-else>
            Pedido entregue: {{ format_date(orderValues.delivered_time, 'DD/MM/YYYY HH:mm') }}
          </div>
        </div>
        <Vue3Lottie animationLink="/images/lottie/order_new.json" :height="200" :width="200" v-if="orderValues.status == 'NEW' || orderValues.status == 'SELECTED' || orderValues.status == 'ACCEPTED'"/>
        <Vue3Lottie animationLink="/images/lottie/order_onpreparing.json" :height="200" :width="200" v-if="orderValues.status == 'ON_PREPARING' || orderValues.status == 'READY'"/>
        <Vue3Lottie animationLink="/images/lottie/order_ondelivery.json" :height="200" :width="200" v-if="orderValues.status == 'ON_DELIVERY'"/>
        <Vue3Lottie animationLink="/images/lottie/order_finished.json" :height="200" :width="200" v-if="orderValues.status == 'DELIVERED'"/>

      </div>
      <div v-else class="mt-8">
          <div class="text-lg font-bold">
            Pedido #{{orderValues.code}}
          </div>
        Estamos a preparar o seu pedido!

        <Vue3Lottie animationLink="/images/lottie/order_onpreparing.json" :height="200" :width="200"/>
      </div>

      
      <div class="flex flex-col items-center my-4" v-if="orderValues.status == 'ON_DELIVERY' && orderValues.driver_name != null && orderValues.driver_phone != null">
        Caso precise de ajuda, estamos aqui para si
        <br/><br/>
        <strong>Contacte o Estafeta</strong> Se houver dúvidas ou questões relacionadas com a entrega, pode entrar diretamente em contacto com o estafeta {{ orderValues.driver_name }}.
        <button class="px-6 py-3 my-2 w-6/12 rounded-3xl bg-primary text-white transition-all hover:bg-primary" @click="callToDriver()">
          Ligar para o estafeta
        </button>
      </div>

      <div class="flex flex-col items-center my-4" v-if="orderValues.status != 'DELIVERED'">
        <strong>Fale com o Restaurante</strong> Para qualquer questão sobre a preparação do pedido, alterações ou problemas, a nossa equipa no restaurante está disponível para o ajudar.

        <button class="px-6 py-3 my-2 w-6/12 rounded-3xl bg-primary text-white transition-all hover:bg-primary" @click="callToStore()">
          Ligar para o restaurante
        </button>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-4 md:mt-20 mt-0">
      <div class="" v-if="orderValues.type == 'delivery'">
        <div class="text-lg font-bold">
          Detalhes da entrega
        </div>    

        <div>          
          <ol class="relative border-s border-gray-500">                  
              <li class="ms-4">
                  <div class="absolute w-3 h-3 bg-gray-500 rounded-full mt-1.5 -start-1.5 border border-white"></div>
                  <time class="mb-1 text-sm font-normal leading-none">{{ store.address}}, {{ store.address_number}} {{ store.appartment_number}} - {{ store.zipcode}}</time>
              </li>
              <li class="ms-4">
                  <div class="absolute w-3 h-3 bg-gray-500 rounded-full mt-1.5 -start-1.5 border border-white"></div>
                  <time class="mb-1 text-sm font-normal leading-none">{{ orderValues.eater_address}}, {{ orderValues.eater_address_number}} {{ orderValues.eater_appartment_number}} - {{ orderValues.eater_zipcode}}</time>
              </li>
          </ol>
        </div>

        <div class="h-96" v-if="orderValues?.id != undefined && orderValues.type == 'delivery'">
          <MapComponent :order_id="orderValues.id" user_type="client" />
        </div>
      </div>
      <div class="" v-else>
        <div class="text-lg font-bold">
          Detalhes para levantamento
        </div>        
        
        <div class="">
          <b>{{ store.name}}</b>
        </div>  
        
        <div class="">
          {{ store.address}}, {{ store.address_number}} {{ store.appartment_number}} - {{ store.zipcode}}
        </div>
        <div class="h-96" v-if="orderValues?.id != undefined && orderValues.type == 'delivery'">
          <MapComponent :order_id="orderValues.id" user_type="client" />
        </div>
      </div>
      
      <div class="" v-if="!guest">
        <div class="text-lg font-bold">
          Resumo do pedido
        </div>
        <div class="">
          <div v-for="item in orderValues.items" v-bind:key="item.id" class="grid grid-cols-2">
            <div class="text-left"><span v-if="item.name != 'Delivery' && item.name != 'Taxa de entrega' && item.name != 'Taxa de Serviço'">{{ item.quantity }} x </span>{{ item.name }}</div>
            <div class="text-right">{{ toCurrency(item.price * item.quantity) }}</div>
          </div>
          <br/>
          <div class="grid grid-cols-2">
            <div class="font-bold">Total</div>
            <div class="text-right">{{ toCurrency(orderValues.price_with_discount) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import * as Icon from "lucide-vue-next";
import moment from 'moment'
import Pusher from 'pusher-js'
</script>

<script>
Pusher.logToConsole = false

export default {
  props: {
    store: { type: Object },
    order: { type: Object },
    guest: { type: Boolean, default: false },
  },
  data() {
    return {
      pusher: null,
      orderValues: {},
    };
  },
  mounted() {
    const self = this;

    this.orderValues = this.order;
    this.pusher = new Pusher('5ffe2ab47cbc08c20ae9', { cluster: 'eu' })
    this.channelOrders = this.pusher.subscribe('orders')
    this.channelOrders.bind('orders', data => {
      console.log(data);

      if(data?.order_id == this.orderValues.code){
        this.orderValues.status = data?.status;

        if(data?.status == 'DELIVERED') location.reload();
      }
    })
  },
  methods: {
    newOrderReceived(data) {
      console.log("newOrderReceived", data);
    },

    callToStore(){
      window.location='tel:'+this.store.phone;
    },

    callToDriver(){
      window.location='tel:'+this.orderValues.driver_phone;
    },

    format_date(value, format){
      if (value) {
        return moment(String(value)).format(format)
      }
    },

    format_float(value){
      return parseFloat(value);
    },

    toCurrency(value) {
      value = parseFloat(value, 2);
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("pt-PT", {
        style: "currency",
        currency: "EUR",
      });

      return formatter.format(value);
    },
  },
};
</script>