 <template>
    <div class="flex items-center justify-between w-full mb-6">
      <h1 class="text-xl font-bold">
          Definições - Gerais
      </h1>
    </div>
    <div class="grid grid-col-1 gap-4">

      <Loading :enabled="loading" />


      <div v-if="!loading" class="flex-row gap-3 grow min-h-full p-2" style="min-height: calc(100vh - 170px);">
          <div
            class="max-w-screen-lg rounded-md border border-gray-100 text-gray-600 shadow-md grid grid-cols-1 gap-2 p-4"
            v-for="hour in hoursItems"
            :key="hour.id"
          >
            <div class="grid grid-cols-5 gap-4">
              <Icon.Trash class="m-auto" @click="removeHour(hour)"/>
              <label class="block text-sm font-medium text-default-900 mb-2">Horário inicial</label>
              <input type="text" @input="updateStartInternalValue(hour, $event)" :value="hour.hour_start" placeholder="Horário inicial" 
                class="block w-full rounded-lg py-2.5 px-4 bg-white border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50">

              <label class="block text-sm font-medium text-default-900 mb-2">Horário final</label>
              <input type="text" @input="updateEndInternalValue(hour, $event)" :value="hour.hour_end" placeholder="Horário final" 
                class="block w-full rounded-lg py-2.5 px-4 bg-white border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50">

            </div>
            <div class="grid md:grid-cols-7 grid-cols-3 gap-2">
              <button v-if="hour.monday == false" @click="hourWeek(hour, 'monday', true)" class="block w-full rounded-lg py-2.5 px-4 bg-white border border-default-200 focus:ring-transparent focus:border-default-200">Segunda</button>
              <button v-else @click="hourWeek(hour, 'monday', false)" class="block w-full rounded-lg py-2.5 px-4 bg-primary border border-default-200 focus:ring-transparent focus:border-default-200">Segunda</button>
          
              <button v-if="hour.tuesday == false" @click="hourWeek(hour, 'tuesday', true)" class="block w-full rounded-lg py-2.5 px-4 bg-white border border-default-200 focus:ring-transparent focus:border-default-200">Terça</button>
              <button v-else @click="hourWeek(hour, 'tuesday', false)" class="block w-full rounded-lg py-2.5 px-4 bg-primary border border-default-200 focus:ring-transparent focus:border-default-200">Terça</button>

              <button v-if="hour.wednesday == false" @click="hourWeek(hour, 'wednesday', true)" class="block w-full rounded-lg py-2.5 px-4 bg-white border border-default-200 focus:ring-transparent focus:border-default-200">Quarta</button>
              <button v-else @click="hourWeek(hour, 'wednesday', false)" class="block w-full rounded-lg py-2.5 px-4 bg-primary border border-default-200 focus:ring-transparent focus:border-default-200">Quarta</button>

              <button v-if="hour.thursday == false" @click="hourWeek(hour, 'thursday', true)" class="block w-full rounded-lg py-2.5 px-4 bg-white border border-default-200 focus:ring-transparent focus:border-default-200">Quinta</button>
              <button v-else @click="hourWeek(hour, 'thursday', false)" class="block w-full rounded-lg py-2.5 px-4 bg-primary border border-default-200 focus:ring-transparent focus:border-default-200">Quinta</button>

              <button v-if="hour.friday == false" @click="hourWeek(hour, 'friday', true)" class="block w-full rounded-lg py-2.5 px-4 bg-white border border-default-200 focus:ring-transparent focus:border-default-200">Sexta</button>
              <button v-else @click="hourWeek(hour, 'friday', false)" class="block w-full rounded-lg py-2.5 px-4 bg-primary border border-default-200 focus:ring-transparent focus:border-default-200">Sexta</button>

              <button v-if="hour.saturday == false" @click="hourWeek(hour, 'saturday', true)" class="block w-full rounded-lg py-2.5 px-4 bg-white border border-default-200 focus:ring-transparent focus:border-default-200">Sábado</button>
              <button v-else @click="hourWeek(hour, 'saturday', false)" class="block w-full rounded-lg py-2.5 px-4 bg-primary border border-default-200 focus:ring-transparent focus:border-default-200">Sábado</button>

              <button v-if="hour.sunday == false" @click="hourWeek(hour, 'sunday', true)" class="block w-full rounded-lg py-2.5 px-4 bg-white border border-default-200 focus:ring-transparent focus:border-default-200">Domingo</button>
              <button v-else @click="hourWeek(hour, 'sunday', false)" class="block w-full rounded-lg py-2.5 px-4 bg-primary border border-default-200 focus:ring-transparent focus:border-default-200">Domingo</button>
            
            </div>
          </div>


          <Icon.Plus @click="hoursAdd()" />
      </div>

  </div>

</template>

<script setup>
import * as Icon from "lucide-vue-next";
import _debounce from "lodash/debounce";
import moment from 'moment';
import draggable from "vuedraggable";
import OrderCard from "./OrderCard.vue";
import Loading from "../Loading.vue";
import FormData from "form-data";
</script>

<script>
export default {
  components: {
    'card': OrderCard,
  },
  props: {
    store: { type: Object },
    companies: { type: Array },
    ubereats_client_id: { type: String, default: '' },
  },
  data() {
    return {
      loading: true,     

      
      loading: true,
      hoursItems: [],
    }  
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      const self = this;
      this.loading = true;

      axios
        .get("/admin/store/" + this.store.id + "/hours")
        .then((response) => {
          self.hoursItems = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },


    hourWeek(storeHour, week, enabled) {
    const self = this;
    this.loading = true;

    switch (week) {
      case 'monday':
        storeHour.monday = enabled;
        break;
      case 'tuesday':
        storeHour.tuesday = enabled;
        break;
      case 'wednesday':
        storeHour.wednesday = enabled;
        break;
      case 'thursday':
        storeHour.thursday = enabled;
        break;
      case 'friday':
        storeHour.friday = enabled;
        break;
      case 'saturday':
        storeHour.saturday = enabled;
        break;
      case 'sunday':
        storeHour.sunday = enabled;
        break;
    }     
    
    axios
      .post("/admin/store/" + this.store.id + "/hours", storeHour)
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        self.loadData();
      });
    },
    updateStartInternalValue(hour, event) {
      this.touched = true;
      this.updateStartValue(hour, event.target.value);
    },
    updateStartValue: _debounce(function (hour, value) {
      this.touched = false;
      hour.hour_start = value;
      axios
      .post("/admin/store/" + this.store.id + "/hours", hour)
      .catch((error) => {
        console.log(error);
      })

    }, 600),
    updateEndInternalValue(hour, event) {
      this.touched = true;
      this.updateEndValue(hour, event.target.value);
    },
    updateEndValue: _debounce(function (hour, value) {
      this.touched = false;
      hour.hour_end = value;

      axios
      .post("/admin/store/" + this.store.id + "/hours", hour)
      .catch((error) => {
        console.log(error);
      })
    }, 600),
  
    hoursAdd() {
      const self = this;
      this.loading = true;
      
      axios
        .post("/admin/store/" + this.store.id + "/hours", {
          hour_start: "11:00",
          hour_end: "23:00",
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: true,
          sunday: true,
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          self.loadData();
        });
    },

    removeHour(storeHour) {
      const self = this;
      
      axios
      .post("/admin/store/" + this.store.id + "/hours/delete", storeHour)
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        self.loadData();
      });
    },
  },
};
</script>
