<template>
  <div v-if="deliveryMode == 'delivery'"
      @click.stop="onAddressSelect"
    class="inline-flex gap-2 cursor-pointer select-none items-center rounded-full border-1 px-3 border-black-300 leading-none text-xs h-10"
  >
    <Icon.MapPin :size="15" />
    <p class="line-clamp-1 min-w-18 py-2" v-if="address == null || address.name == ''">
        Digite aqui seu endereço        
    </p>
    <p class="line-clamp-1 min-w-18 py-2" v-else>
        {{ address.name }}, {{ address.address_number }} {{ address.zipcode }}
    </p>
  </div>
</template>

<script setup>
import * as Icon from "lucide-vue-next";
</script>

<script>
export default {
  props: {
    currentCart: { type: Object },
    currentAddress: { type: Object },
    addresses: { type: Array },
  },
  data() {
    return {
      error: "",
      searchText: "",
      addressName: "",
      lat: 0,
      lng: 0,
      deliveryMode: "delivery",
      address: "",
      sugestionsList: [],
      total: 0,
      popupOpened: false,
      historyMode: true,
      confirmMode: false,
      confirmPlace: null,
      confirmPlace2: null,
      confirmPlaceId: 0,
      confirmObs: "",
      confirmObsDefault: "",
      appartment_number: "",
    };
  },
  mounted() {
    this.emitter.on("cart.updated", this.onCartUpdated);
    this.emitter.on("address.select", this.onAddressSelect);

    this.deliveryMode = this.currentCart.deliveryMode ?? "delivery";
    this.address = this.currentAddress;
  },
  created() {},
  methods: {
    onCartUpdated(data) {
      this.deliveryMode = data.deliveryMode;
      this.address = data.currentAddress;
    },
    onAddressSelect() {
      this.emitter.emit("address.select");
    },
  },
};
</script>
