import './bootstrap';
import { createApp } from 'vue';
import Cart from './Components/Cart.vue';
import ProductGallery from './Components/ProductGallery.vue';
import StoreAd from './Components/StoreAd.vue';
import DeliveryAddress from './Components/DeliveryAddress.vue';
import DeliveryAddressMobile from './Components/DeliveryAddressMobile.vue';
import ImageComponent from './Components/ImageComponent.vue';
import ImageComponentAdmin from './Components/ImageComponentAdmin.vue';
import ImageUpload from './Components/ImageUpload.vue';
import CartView from './Components/CartView.vue';
import CartResume from './Components/CartResume.vue';
import OrderView from './Components/OrderView.vue';
import IntegratorIcon from './Components/IntegratorIcon.vue';
import ProductSelector from './Components/ProductSelector.vue';
import PauseNewOrdersPopup from './Components/PauseNewOrdersPopup.vue';


import OrdersManager from './Components/OrdersManager.vue';
import MapComponent from './Components/MapComponent.vue';

//admin
import AdminStore from './Components/Admin/AdminStore.vue';
import AdminOrder from './Components/Admin/AdminOrder.vue';
import AdminCompany from './Components/Admin/AdminCompany.vue';
import AdminMenuManager from './Components/Admin/AdminMenuManager.vue';
import AdminConfigManager from './Components/Admin/AdminConfigManager.vue';
import AdminHoursManager from './Components/Admin/AdminHoursManager.vue';
import AdminVariablesManager from './Components/Admin/AdminVariablesManager.vue';
import AdminFinance from './Components/Admin/AdminFinance.vue';
import AdminNotification from './Components/Admin/AdminNotification.vue';

//merchant
import MerchantDashboard from './Components/Merchant/MerchantDashboard.vue';
import MerchantStore from './Components/Merchant/MerchantStore.vue';
import MerchantMenuManager from './Components/Merchant/MerchantMenuManager.vue';
import MerchantConfigManager from './Components/Merchant/MerchantConfigManager.vue';
import MerchantHoursManager from './Components/Merchant/MerchantHoursManager.vue';
import MerchantIntegrations from './Components/Merchant/MerchantIntegrations.vue';
import MerchantNotification from './Components/Merchant/MerchantNotification.vue';

//fleet
import FleetOrdersManager from './Components/Fleet/OrdersManager.vue';
import FleetNotification from './Components/Fleet/FleetNotification.vue';

import Profile from './Components/Profile.vue';
import Vue3Lottie from 'vue3-lottie'
import VueGoogleMaps from '@fawmi/vue-google-maps';
import print from 'vue3-print-nb'

import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import mitt from 'mitt';

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

const emitter = mitt();
const app = createApp({});

app.component("Cart", Cart);
app.component("ProductGallery", ProductGallery);
app.component("StoreAd", StoreAd);
app.component("DeliveryAddress", DeliveryAddress);
app.component("DeliveryAddressMobile", DeliveryAddressMobile);

const globalOptionsVueTelInput = {
  mode: 'international',
  defaultCountry: 'pt',
  autoDefaultCountry: false,
  validCharactersOnly: true,
  inputOptions: {
    placeholder: '',
  },
};
app.use(VueTelInput, globalOptionsVueTelInput);


app.component("OrdersManager", OrdersManager);
app.component("MapComponent", MapComponent);

// Admin
app.component("AdminStore", AdminStore);
app.component("AdminOrder", AdminOrder);
app.component("AdminCompany", AdminCompany);
app.component("AdminMenuManager", AdminMenuManager);
app.component("AdminConfigManager", AdminConfigManager);
app.component("AdminHoursManager", AdminHoursManager);
app.component("AdminVariablesManager", AdminVariablesManager);
app.component("AdminFinance", AdminFinance);
app.component("AdminNotification", AdminNotification);

// Fleet
app.component("FleetOrdersManager", FleetOrdersManager);
app.component("FleetNotification", FleetNotification);


//Merchant
app.component("MerchantDashboard", MerchantDashboard);
app.component("MerchantStore", MerchantStore);
app.component("MerchantMenuManager", MerchantMenuManager);
app.component("MerchantConfigManager", MerchantConfigManager);
app.component("MerchantHoursManager", MerchantHoursManager);
app.component("MerchantIntegrations", MerchantIntegrations);
app.component("MerchantNotification", MerchantNotification);





app.component("ImageComponent", ImageComponent);
app.component("ImageComponentAdmin", ImageComponentAdmin);
app.component("ImageUpload", ImageUpload);
app.component("CartView", CartView);
app.component("OrderView", OrderView);
app.component("CartResume", CartResume);
app.component("IntegratorIcon", IntegratorIcon);
app.component("ProductSelector", ProductSelector);
app.component("PauseNewOrdersPopup", PauseNewOrdersPopup);



app.component("Profile", Profile);
app.use(Vue3Lottie)
app.use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyCRsxESXWnCavSql_W5eCENf_gwtqV0yoA',
    },
    installComponents: true
  })
app.use(print)
app.component('EasyDataTable', Vue3EasyDataTable);

app.config.globalProperties.emitter = emitter;

app.mount("#app");
