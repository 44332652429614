<template>
  <div>
    <div class="flex items-center justify-between w-full mb-6">
      <h4 class="text-xl font-bold">
          Performance
      </h4>
    </div>
  </div>
  <div class="grid grid-col-1 gap-4">
    <div class="">
      <div class="grid md:grid-cols-2 grid-cols-1 gap-4">
        <div class="">
          <label class="block text-sm font-medium text-default-900 mb-2">Período</label>
          <select v-model="currentPeriod" @input="updatePeriod($event.target.value)"
              class="block w-full bg-transparent text-sm rounded-3xl border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50">
              <option v-for="period in periods" :key="period.label" :value="period.id" >{{ period.label }}</option>
          </select>
        </div>
      </div>
    </div>

    <Loading :enabled="loading" />

    <div v-if="!loading" class="relative grid grid-cols-2 gap-4 md:grid-cols-3 min-w-0 mt-6  shadow-soft-xl">
      <card title="Vendas" :data="toCurrency(data.sales)" icon="Euro"  />    
      <card title="Lucro" :data="toCurrency(data.net)" icon="Euro"  />    
      <card title="Pedidos" :data="data.orders" icon="ScrollText"  />    
      <card title="Ticket médio" :data="toCurrency(data.ticket)" icon="Euro"  />
      <card title="Tempo médio preparo" :data="data.prepare_avg + ' minutos'" icon="Clock"  />
      <card title="Tempo médio entrega" :data="data.delivery_avg + ' minutos'" icon="Clock"  />
    </div>
  </div>
</template>

<script setup>
import * as Icon from "lucide-vue-next";
import moment from 'moment';
import DashboardCard from "./DashboardCard.vue";
import Loading from "../Loading.vue";
</script>

<script>
export default {
  components: {
    'card': DashboardCard,
  },
  props: {
    stores: { type: Array },
  },
  data() {
    return {
      loading: true,
      currentStore: "",
      currentPeriod: "",
      data: {
        sales: 0,
        new: 0,
        net: 0,
        orders: 0,
        ticket: 0,
        prepare_avg: 0,
        delivery_avg: 0,
      },
      periods: [
        {id:0, label: 'Hoje', start: moment().format('YYYY-MM-DD'), end: moment().add(1, 'd').format('YYYY-MM-DD'),},
        {id:1, label: moment().startOf('isoWeek').format('DD/MM/YYYY')+ ' - '+ moment().endOf('isoWeek').format('DD/MM/YYYY'), start: moment().startOf('isoWeek').format('YYYY-MM-DD'), end: moment().endOf('isoWeek').format('YYYY-MM-DD'),},
        {id:2, label: moment().startOf('isoWeek').add(-7, 'd').format('DD/MM/YYYY')+ ' - '+ moment().endOf('isoWeek').add(-7, 'd').format('DD/MM/YYYY'), start: moment().startOf('isoWeek').add(-7, 'd').format('YYYY-MM-DD'), end: moment().endOf('isoWeek').add(-7, 'd').format('YYYY-MM-DD'),},
        {id:3, label: moment().startOf('isoWeek').add(-14, 'd').format('DD/MM/YYYY')+ ' - '+ moment().endOf('isoWeek').add(-14, 'd').format('DD/MM/YYYY'), start: moment().startOf('isoWeek').add(-14, 'd').format('YYYY-MM-DD'), end: moment().endOf('isoWeek').add(-14, 'd').format('YYYY-MM-DD'),},
        {id:3, label: moment().startOf('isoWeek').add(-21, 'd').format('DD/MM/YYYY')+ ' - '+ moment().endOf('isoWeek').add(-21, 'd').format('DD/MM/YYYY'), start: moment().startOf('isoWeek').add(-21, 'd').format('YYYY-MM-DD'), end: moment().endOf('isoWeek').add(-21, 'd').format('YYYY-MM-DD'),},
        {id:3, label: moment().startOf('isoWeek').add(-28, 'd').format('DD/MM/YYYY')+ ' - '+ moment().endOf('isoWeek').add(-28, 'd').format('DD/MM/YYYY'), start: moment().startOf('isoWeek').add(-28, 'd').format('YYYY-MM-DD'), end: moment().endOf('isoWeek').add(-28, 'd').format('YYYY-MM-DD'),},
      ],
    };
  },
  mounted() {
    this.currentStore = '*';
    this.currentPeriod = this.periods[0]?.id ?? '';

    this.loadData();
  },
  methods: {
    loadData() {
      const self = this;
      this.loading = true;

      console.log('this.currentPeriod', this.currentPeriod);

      axios
        .post("/merchant/data", {'store': this.currentStore, 'start': this.periods[this.currentPeriod].start, 'end': this.periods[this.currentPeriod].end})
        .then((response) => {
          self.data = response.data;
        })
        .catch((error) => {
          alert(error.response.data.error ?? 'Error');
        })
        .finally(() => {
          this.loading = false;
        });


    },

    updateStore(store){
      this.currentStore = store;
      this.loadData();
    },

    updatePeriod(period){
      this.currentPeriod = period;
      this.loadData();
    },

    toCurrency(value) {
      value = parseFloat(value, 2);
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("pt-PT", {
        style: "currency",
        currency: "EUR",
      });

      return formatter.format(value);
    },
  },
};
</script>
